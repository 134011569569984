import _get from "lodash/get";
import moment from "moment";
import * as ActionTypes from "../actions";
import { setFormValues } from "../utilities/transactionFormBuilder";

const initialState = {
  id: null,
  ids: [],
  transactedAt: moment(new Date()).format("YYYY/MM/DD"),
  amount: "",
  asEligibleInvoice: false,
  originalAmount: "",
  originalAmountCurrencyId: null,
  exchangePolicy: "floor",
  exchangeRate: 1.0,
  currencyId: null, // 外貨の一括更新時に送信パラメータ用
  rateReferenceType: "manual",
  shopName: "",
  destination: "",
  taxCategoryName: "",
  expenseAmountPerTaxCategories: [],
  categoryName: "",
  parentCategoryName: "",
  nestedCategoryNames: [],
  creditCategoryName: "",
  taxCategory: null,
  category: null,
  nestedCategories: [],
  report: null, // { id: '', title: '' }
  reportTitle: "",
  reportId: null,
  preReport: null, // { id: '', title: '' }
  preReportTitle: "",
  preReportId: null,
  sequenceNum: null,
  project: null,
  genericFields: [],
  companions: [],
  isCorporate: false,
  isElectronicReceiptImage: false,
  comment: _get(userPreferences, "preference.commentFormat"),
  withholding: "",
  address: "",
  fullName: "",
  route: null,
  editable: true,
  status: null,
  permissions: {},
  ownerId: null,
  isTaxAmountShow: true,
  isDeleted: false,
  validations: {
    transactedAt: ["required"],
    shopName: ["required", "maxSize[255]"],
    taxCategoryName: ["maxSize[255]", "funcCallRequired[validateTaxCategory]"],
    categoryName: ["maxSize[255]", "funcCallRequired[validateCategory]"],
    creditCategoryName: [
      "maxSize[255]",
      "funcCallRequired[validateCreditCategory]",
    ],
    reportTitle: ["maxSize[255]", "funcCallRequired[validateReportTitle]"],
    groupName: ["maxSize[255]"],
  },
  submitOnlyFilledInput: false,
  mergeableAggregation: null,
  receiptImages: { foreside: [], backside: [] },
  registratedNumber: null,
  localReceiptFile: { foreside: null, backside: null },
  directProductTableId: null,
  calculationFormulaVariableInputs: [],
  costAllocations: [
    {
      payerType: "Group",
      payerId: _get(userPreferences, "department.id"),
      payerName: _get(userPreferences, "department.name"),
      numerator: 100,
      denominator: 100,
    },
  ],
  showDisabledCategories: false,
  invoicingOrganization: null,
  paidAddress: null,

  formValues: {},
  department: {},
  transitPayee: "",
  /** {OperatorEntry} オペレータ入力 */
  operatorEntry: null,
};

function arrayWithItemAt(index, item, array) {
  return new Array(Math.max(array.length, index + 1)).fill(null).map((x, i) => {
    if (i === index) {
      return item;
    }

    return array[i] || null;
  });
}

const formData = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_TRANSACTION: {
      return {
        ...action.formData,
      };
    }
    case ActionTypes.SET_FORM_DATA: {
      return {
        ...state,
        [action.prop]: action.value,
        formValues: setFormValues(
          state.formValues,
          action.prop,
          action.value,
          action.defaultValues,
        ),
      };
    }
    case ActionTypes.AUTO_SET_WITHHOLDING: {
      return {
        ...state,
        withholding: action.value,
        formValues: setFormValues(
          state.formValues,
          "withholding",
          action.value,
          null,
        ),
      };
    }
    case ActionTypes.SET_NESTED_CATEGORY_NAME: {
      return {
        ...state,
        nestedCategoryNames: arrayWithItemAt(
          action.level,
          action.value,
          state.nestedCategoryNames,
        ),
      };
    }
    case ActionTypes.SET_NESTED_CATEGORY: {
      return {
        ...state,
        nestedCategories: arrayWithItemAt(
          action.level,
          action.value,
          state.nestedCategories,
        ),
      };
    }
    case ActionTypes.SET_NESTED_CATEGORIES: {
      return {
        ...state,
        nestedCategories: action.nestedCategories,
      };
    }
    case ActionTypes.SET_NESTED_CATEGORY_NAMES: {
      return {
        ...state,
        nestedCategoryNames: action.nestedCategoryNames,
      };
    }
    case ActionTypes.SET_META_DATA: {
      return {
        ...state,
        formValues: setFormValues(
          state.formValues,
          action.prop,
          action.value,
          action.defaultValues,
        ),
      };
    }
    case ActionTypes.SET_LOCAL_RECEIPT_FILE: {
      const { isBackside, file } = action.payload;
      const side = isBackside ? "backside" : "foreside";

      return {
        ...state,
        localReceiptFile: {
          ...state.localReceiptFile,
          [side]: action.payload.file
            ? {
                isBackside,
                contentType: file.type,
                file,
                rotation: 0,
              }
            : null,
        },
      };
    }
    case ActionTypes.CLEAR_LOCAL_RECEIPT_FILE: {
      return {
        ...state,
        localReceiptFile: { foreside: null, backside: null },
      };
    }
    case ActionTypes.ROTATE_RECEIPT_FILE: {
      const { file, rotation } = action.payload;
      const isLocalFile = !("id" in file);
      const side = file.isBackside ? "backside" : "foreside";
      const localReceiptFile = isLocalFile
        ? {
            ...state.localReceiptFile,
            [side]: {
              ...state.localReceiptFile[side],
              rotation,
            },
          }
        : state.localReceiptFile;
      const receiptImages = isLocalFile
        ? state.receiptImages
        : {
            ...state.receiptImages,
            [side]: state.receiptImages[side].map((x) => {
              if (x.id === file.id) {
                return x;
              }

              return { ...x, rotation };
            }),
          };

      return {
        ...state,
        localReceiptFile,
        receiptImages,
      };
    }
    case ActionTypes.DELETE_RECEIPT_FILE: {
      const { file } = action.payload;
      const isLocalFile = !("id" in file);
      const side = file.isBackside ? "backside" : "foreside";
      const localReceiptFile = isLocalFile
        ? {
            ...state.localReceiptFile,
            [side]: null,
          }
        : state.localReceiptFile;
      const receiptImages = isLocalFile
        ? state.receiptImages
        : {
            ...state.receiptImages,
            [side]: state.receiptImages[side].filter((x) => x.id !== file.id),
          };

      return {
        ...state,
        localReceiptFile,
        receiptImages,
      };
    }
    case ActionTypes.RESET_RECEIPT_FILES: {
      const { expense } = action.payload;
      // 画像表示に関連するプロパティを全て更新する
      // 更新途中に別の経費モーダルが開かれている場合があるので、
      // stateのidと一致する場合のみ画像に関するプロパティを更新する
      if (state.id === expense.id) {
        return {
          ...state,
          localReceiptFile: { foreside: null, backside: null },
          receiptImages: { ...expense.receiptImages },
        };
      }

      return state;
    }
    case ActionTypes.SET_EXCHANGE_RATE: {
      return {
        ...state,
        exchangeRate: action.value,
        formValues: {
          ...state.formValues,
          [action.key]: {
            ...state.formValues[action.key],
            exchangeRate: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_CURRENCY_ID_MULTIPLE_EDITING: {
      const key = "currency_and_rate_input";
      return {
        ...state,
        currencyId: action.value,
        formValues: {
          ...state.formValues,
          [key]: {
            ...state.formValues[key],
            currencyId: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_RATE_REFERENCE_TYPE_MULTIPLE_EDITING: {
      const key = "currency_and_rate_input";
      return {
        ...state,
        rateReferenceType: action.value,
        formValues: {
          ...state.formValues,
          [key]: {
            ...state.formValues[key],
            rateReferenceType: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_EXCHANGE_POLICY: {
      return {
        ...state,
        exchangePolicy: action.value || "floor",
        formValues: {
          ...state.formValues,
          [action.key]: {
            ...state.formValues[action.key],
            exchangePolicy: action.value || "floor",
          },
        },
      };
    }
    case ActionTypes.SET_AS_ELIGIBLE_INVOICE: {
      const parentKey = "eligible_invoice_confirmation_input";
      const targetKey = "asEligibleInvoice";

      return {
        ...state,
        [targetKey]: action.value,
        formValues: {
          ...state.formValues,
          [parentKey]: {
            ...state.formValues[parentKey],
            [targetKey]: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_REGISTRATED_NUMBER: {
      const parentKey = "eligible_invoice_confirmation_input";
      const targetKey = "registratedNumber";

      return {
        ...state,
        [targetKey]: action.value,
        formValues: {
          ...state.formValues,
          [parentKey]: {
            ...state.formValues[parentKey],
            [targetKey]: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_INVOICING_ORGANIZATION: {
      const parentKey = "eligible_invoice_confirmation_input";
      const targetKey = "invoicingOrganization";

      return {
        ...state,
        [targetKey]: action.value,
        formValues: {
          ...state.formValues,
          [parentKey]: {
            ...state.formValues[parentKey],
            [targetKey]: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_PAID_ADDRESS: {
      const parentKey = "eligible_invoice_confirmation_input";
      const targetKey = "paidAddress";

      return {
        ...state,
        [targetKey]: action.value,
        formValues: {
          ...state.formValues,
          [parentKey]: {
            ...state.formValues[parentKey],
            [targetKey]: action.value,
          },
        },
      };
    }
    case ActionTypes.SET_OPERATOR_ENTRY: {
      return {
        ...state,
        operatorEntry: action.payload.operatorEntry,
      };
    }
    case ActionTypes.NEW_EXPENSE_FETCHED_CATEGORIES: {
      return {
        ...state,
        nestedCategories: action.payload.nestedCategories,
        nestedCategoryNames: action.payload.nestedCategories.map((c) => c.name),
        category: action.payload.defaultCategory,
        taxCategory: action.payload.taxCategory,
        asEligibleInvoice: action.payload.asEligibleInvoice,
        comment: action.payload.comment,
        formValues: {
          ...state.formValues,
          ...setFormValues(
            state.formValues,
            "category",
            action.payload.defaultCategory,
            action.payload.defaultValue,
          ),
          ...setFormValues(
            state.formValues,
            "taxCategory",
            action.payload.taxCategory,
          ),
          ...setFormValues(state.formValues, "comment", action.payload.comment),
        },
      };
    }
    case ActionTypes.EDIT_EXPENSE_FETCHED_CATEGORIES: {
      return {
        ...state,
        category: action.payload.currentCategory,
        nestedCategories: action.payload.nestedCategories,
        nestedCategoryNames: action.payload.nestedCategories.map((c) => c.name),
        formValues: {
          ...state.formValues,
          ...setFormValues(
            state.formValues,
            "category",
            action.payload.currentCategory,
            {},
          ),
        },
      };
    }
    default:
      return state;
  }
};

// デフォルトの経費として、他のファイルからも参照可能なようにexportしておく
export const initialTransaction = initialState;
export default formData;
