import { Category } from "../types";

interface ReturnTypes {
  readonly categories: Category[];
  readonly categoriesByParentId: { [key: string]: Category[] };
}

export const addCategoryToTop = (
  disabledCategory: Category,
  categories: Category[],
  categoriesByParentId: { [key: string]: Category[] },
): ReturnTypes => {
  const category = {
    ...disabledCategory,
    parnetId: null,
    selectable: true,
    level: 0,
    enable: false,
  };
  return {
    categories: [category, ...categories],
    categoriesByParentId: {
      ...categoriesByParentId,
      top: [category, ...(categoriesByParentId?.top || [])],
    },
  };
};
