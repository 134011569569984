/**
 * 親IDでデータをグループ化する。
 * 親IDが null の場合、rootKey で指定したキーに格納する。
 * @param data 対象のデータ
 * @param rootKey ルートのキー
 * @returns 親IDでグループ化されたデータ
 */
export const buildCategoriesByParentId = <
  T extends { parentId: string | null },
>(
  data: T[],
  rootKey = "top",
): { [key: string]: T[] } => {
  return data.reduce((acc, current) => {
    return {
      ...acc,
      [current.parentId || rootKey]: (
        acc[current.parentId || rootKey] || []
      ).concat(current),
    };
  }, {});
};
