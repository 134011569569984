import { Params } from "../fetch";

interface FormData {
  readonly formId: string;
  readonly ownerId: string;
  readonly showDisabledCategories: boolean;
  readonly directProductTableId: string;
  readonly preReportTitle: string;
}

export const buildFetchParams = (
  formData: FormData | null,
  includeNotSelectable: boolean,
): Params => {
  const formId = formData?.formId || "normal";

  return {
    enable: !(formData?.showDisabledCategories || false),
    ownerId: formData?.ownerId || null,
    formId:
      formId === "allowance"
        ? formData?.directProductTableId || "normal"
        : formId,
    forPreReport: !formData?.preReportTitle?.length,
    taxCategory: true, // 選択された経費科目の税区分を利用している部分があるので、これは必要
    includeNotSelectable,
  };
};
