import { fetchAsyncAll } from "actions/AsyncAction";
import Api from "utilities/api";
import { snakecaseKeys } from "utilities/Utils";
import { Category } from "./types";

interface Response {
  readonly data: Category[];
  readonly count: number;
}

export interface Params {
  readonly enable: boolean;
  readonly ownerId: string | null;
  readonly formId: string;
  readonly forPreReport: boolean;
  readonly taxCategory: boolean;
  readonly includeNotSelectable: boolean;
}

export const fetchPersonalCategories = async (
  queryParams: Params,
): Promise<Response> => {
  const res = await fetchAsyncAll(
    (params) =>
      Api.personalCategories
        .index(
          snakecaseKeys({
            enable: queryParams.enable,
            ownerId: queryParams.ownerId,
            formId: queryParams.formId,
            forPreReport: queryParams.forPreReport,
            taxCategory: queryParams.taxCategory,
            includeNotSelectable: queryParams.includeNotSelectable,
            ...params,
          }),
        )
        .then((result) => {
          return { data: result.categories, count: result.count };
        }),
    {
      /* APIを投げる際の追加パラメータは特になし */
    },
    { limit: 200 },
  );
  return res;
};
