import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { configureReducers } from "reducers";
import { createStore } from "redux";
import { createEnhancerWithExtraArgument } from "utilities/react_helper";
import {
  clearLocalReceiptFile,
  resetTransaction,
} from "../../transactions/actions";
import { CategoryRepository } from "../../transactions/middlewares/personalCategories/CategoryRepository";
import reducers from "../../transactions/reducers";
import { initialTransaction } from "../../transactions/reducers/formData";
import {
  buildFormFields,
  buildFormValues,
} from "../../transactions/utilities/transactionFormBuilder";
import ExpenseSingleViewer from "./ExpenseSingleViewer";

export class ExpenseSingleViewerWrapper extends Component {
  personalCategoryRepository = new CategoryRepository();

  constructor(props) {
    super(props);

    this.handleCreateSuccess = this.handleCreateSuccess.bind(this);

    const extraArgument = {
      repositories: {
        personalCategoryRepository: this.personalCategoryRepository,
      },
    };
    this.store = createStore(
      configureReducers(reducers),
      this.processProps(props),
      createEnhancerWithExtraArgument({ extraArgument }),
    );
  }

  componentDidMount() {
    if (this.shouldResetExpense()) {
      this.store.dispatch(resetTransaction(this.processProps(this.props)));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldResetExpense(prevProps)) {
      this.store.dispatch(resetTransaction(this.processProps(this.props)));
    }
  }

  shouldResetExpense(prevProps = {}) {
    if (this.props.show !== prevProps.show && this.props.show === true) {
      return true;
    }

    if (
      this.props.transaction?.id !== prevProps.transaction?.id &&
      this.props.transaction?.id
    ) {
      return true;
    }

    return false;
  }

  // todo Modalを開く時のformFieldsの初期化処理
  processProps(props) {
    const formValues = buildFormValues(props.formFields, props.transaction);

    // 汎用マスタのフィールドは動的である。
    // 経費の「新規作成」時であれば(= formValuesの値が{}であれば)、ここでformFieldsから組み立ててformDataに渡している
    // 経費の「編集」時であれば、formValuesに入っている値をそのまま渡す
    const genericFields =
      formValues === {}
        ? props.formFields
            .filter((f) => f.type === "generic_fields_input")
            .map((f) => ({
              dataSetId: f.dataSetId,
              items: [],
            }))
        : formValues.generic_fields_input;

    return {
      formData: {
        ...initialTransaction,
        ...props.transaction,
        formValues,
        genericFields,
        authority: props.authority,
      },
      formState: {
        fields: buildFormFields(
          props.formFields,
          {
            authority: props.authority,
            status: get(props.transaction, "status", null),
          },
          formValues,
        ),
      },
    };
  }

  handleCreateSuccess(expenses, options) {
    const { reuseInput } = options;

    if (reuseInput) {
      this.store.dispatch(clearLocalReceiptFile());
    }

    this.props.onCreateSuccess(expenses, options);
  }

  render() {
    const {
      show,
      closeModal,
      authority,
      onDestroySuccess,
      onUpdateSuccess,
      onCreateSuccess,
      onDetachSuccess,
      onDeleteImage,
      onRotateImage,
      onUnread,
      ownerId,
      shouldSelectSelfAsCompanion,
      onGoToNextExpense,
      onGoToPreviousExpense,
      onSplitSuccess,
    } = this.props;

    return (
      <Provider store={this.store}>
        <ExpenseSingleViewer
          authority={authority}
          closeModal={closeModal}
          fromPreTransaction={this.props.fromPreTransaction}
          matchedOriginalReceipt={this.props.transaction.matchedOriginalReceipt}
          onCreateSuccess={this.handleCreateSuccess}
          onDeleteImage={onDeleteImage}
          onDestroySuccess={onDestroySuccess}
          onDetachSuccess={onDetachSuccess}
          onSplitSuccess={onSplitSuccess}
          onGoToNextExpense={onGoToNextExpense}
          onGoToPreviousExpense={onGoToPreviousExpense}
          onRotateImage={onRotateImage}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
          onUnread={onUnread}
          onUpdateSuccess={onUpdateSuccess}
          ownerId={ownerId}
          preReportDepartment={this.props.transaction.preReportDepartment}
          preReportId={this.props.transaction.preReportId}
          preReportSequenceNum={this.props.transaction.preReportSequenceNum}
          preReportTitle={this.props.transaction.preReportTitle}
          receiptExpenseMatching={this.props.transaction.receiptExpenseMatching}
          reportId={this.props.transaction.reportId}
          reportSequenceNum={this.props.transaction.reportSequenceNum}
          reportTitle={this.props.transaction.reportTitle}
          shouldSelectSelfAsCompanion={shouldSelectSelfAsCompanion}
          show={show}
          isCreatedByAggregation={this.props.transaction.isCreatedByAggregation}
          isElectronicReceiptImage={
            this.props.transaction.isElectronicReceiptImage
          }
          transitPayee={this.props.transaction.transitPayee}
        />
      </Provider>
    );
  }
}

ExpenseSingleViewerWrapper.defaultProps = {
  fromPreTransaction: false,
  onCreateSuccess() {},
  onDeleteImage() {},
  onDestroySuccess() {},
  onDetachSuccess() {},
  onSplitSuccess() {},
  onUnread() {},
  onUpdateSuccess() {},
};

ExpenseSingleViewerWrapper.propTypes = {
  authority: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  fromPreTransaction: PropTypes.bool.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  onDestroySuccess: PropTypes.func.isRequired,
  onDetachSuccess: PropTypes.func.isRequired,
  onSplitSuccess: PropTypes.func,
  onGoToNextExpense: PropTypes.func,
  onGoToPreviousExpense: PropTypes.func,
  onRotateImage: PropTypes.func,
  onUnread: PropTypes.func,
  onUpdateSuccess: PropTypes.func.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  transaction: PropTypes.object,
};

export default ExpenseSingleViewerWrapper;
