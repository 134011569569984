import { Category } from "../types";

interface Suggestion {
  readonly total: Category[];
  readonly current: Category[];
}

export const buildNestedCategoriesSuggestions = (
  category: Category | null,
  categories: Category[],
  categoriesByParentId: { [key: string]: Category[] },
): Suggestion[] => {
  if (category) {
    if (categories.length > 0 && categoriesByParentId) {
      const categoryInMaster = categories.find((c) => c.id === category.id);

      if (categoryInMaster) {
        // 選択中の経費科目がマスタに存在する。
        return getNestedSuggestions(
          categoryInMaster,
          categories,
          categoriesByParentId,
        );
      }
      // 選択中の経費科目がマスタに存在しない。
      return [
        {
          total: [category, ...(categoriesByParentId?.top || [])],
          current: [category, ...(categoriesByParentId?.top || [])],
        },
      ];
    }
    // 経費科目マスタが取得されていない。
    return [{ total: [category], current: [category] }];
  }
  // 経費科目が設定されていない。
  return [
    {
      total: categoriesByParentId?.top || [],
      current: categoriesByParentId?.top || [],
    },
  ];
};

// 子科目 → 親科目の順で再帰的に自分と同じ階層の候補の配列を作成する
const getSuggestionRecursive = (
  category: Category,
  categories: Category[],
  nestedCategoriesByParentId: { [key: string]: Category[] },
  nestedSuggestions: Suggestion[] = [],
): Suggestion[] => {
  const parentCategory = categories.find((c) => c.id === category.parentId);
  const siblings = nestedCategoriesByParentId[parentCategory?.id || "top"];
  const nextNestedSuggestions = nestedSuggestions.concat({
    total: siblings,
    current: siblings,
  });

  if (!category?.parentId || !parentCategory) {
    return nextNestedSuggestions.reverse();
  }
  return getSuggestionRecursive(
    parentCategory,
    categories,
    nestedCategoriesByParentId,
    nextNestedSuggestions,
  );
};

export const getNestedSuggestions = (
  category: Category,
  categories: Category[],
  nestedCategoriesByParentId: { [key: string]: Category[] },
): Suggestion[] => {
  let nestedCategoriesSuggestions = getSuggestionRecursive(
    category,
    categories,
    nestedCategoriesByParentId,
  );

  // selectable=falseの経費科目が入力されている経費を編集するとき
  // 入力されていた経費科目が事業所設定で親科目に変更された場合に発生する
  if (!category.selectable) {
    const children = categories.filter((c) => c.parentId === category.id);
    nestedCategoriesSuggestions = nestedCategoriesSuggestions.concat({
      total: children,
      current: children,
    });
  }
  return nestedCategoriesSuggestions;
};
