import { buildCategoriesByParentId } from "./builders/buildCategoriesByParentId";
import { fetchPersonalCategories, Params } from "./fetch";
import { Category } from "./types";

interface Cache {
  readonly categories: Category[];
  readonly categoriesByParentId: { [key: string]: Category[] };
}

/**
 * 経費科目レポジトリ
 * @example
 *   const repository = new CategoryRepository();
 *   const cache = await repository.fetch(params);
 */
export class CategoryRepository {
  private static emptyCache: Cache = {
    categories: [],
    categoriesByParentId: {},
  };

  private cache: Map<string, Cache> = new Map();

  private lockedHashes: { [key: string]: boolean } = {};

  /**
   * 経費科目データを取得する。
   * - リモートから取得した経費科目データをキャッシュする。
   * - リモートから取得する際には、同じパラメータでのリクエストが重複しないようにロックする。
   * - リクエストパラメータが同じ場合は、キャッシュを返す。
   * - リクエストパラメータが異なる場合は、リモートから取得し、キャッシュする。
   * - リクエストパラメータが同じでも、リクエスト中の場合は空のキャッシュを返す。
   * @param params リクエストパラメータ
   * @returns 経費科目データ
   */
  async fetch(params: Params): Promise<Cache> {
    const hash = this.generateHash(params);
    const hitCache = this.cache.get(hash);

    if (hitCache) {
      return hitCache;
    }

    if (this.isLocked(hash)) {
      return CategoryRepository.emptyCache;
    }
    this.lock(hash);
    const categories = await this.fetchRemote(params);
    this.unlock(hash);

    const categoriesByParentId =
      buildCategoriesByParentId<Category>(categories);
    const data = { categories, categoriesByParentId };

    this.cache.set(hash, data);

    return data;
  }

  private async fetchRemote(params): Promise<Category[]> {
    const { data } = await fetchPersonalCategories(params);
    return data;
  }

  private generateHash(params: Params): string {
    const sortedParams = Object.keys(params)
      .sort()
      .reduce((acc, key) => {
        acc[key] = params[key];
        return acc;
      }, {} as Params);

    return JSON.stringify(sortedParams);
  }

  private lock(hash): void {
    this.lockedHashes[hash] = true;
  }

  private unlock(hash): void {
    this.lockedHashes[hash] = false;
  }

  private isLocked(hash): boolean {
    return this.lockedHashes[hash];
  }
}
