import ApiBase from './api_base';

const relativePath = 'ir';
const authPath = '/api/v1/auth';

export default new ApiBase('v1', { relativePath, authPath, afterSignOutPath: '/ir/sign_in' })
  .post('sign_up')
  .resources('/receiving_images', { only: ['index', 'show'] })
  .post('/receiving_images/{id}/share')
  .build();
