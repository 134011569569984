import * as actions from 'applications/transactions/actions/transactionTable';
import * as manualMergeActions from 'applications/transactions/actions/manualMergeActions';
import ExpenseSingleViewerWrapper from 'applications/expenses/single_views/ExpenseSingleViewerWrapper';
import React, { useEffect, useRef, useState } from 'react';
import ReportSendModal from 'applications/report_requests/containers/ReportSendModal';
import formFieldsForMultipleEdit from 'values/form_fields_for_multiple_edit';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import makeTransactionTableStateMapper from 'applications/transactions/selectors/transactionTable';
import { Button } from 'components/renewaled_ui/buttons';
import { DeleteConfirmModal } from 'applications/expenses/tables/ExpenseTable/DeleteConfirmModal';
import { Dropdown, DropdownToggleButton, MenuItem } from 'components/renewaled_ui/Dropdown';
import { ExpenseTable } from 'applications/expenses/tables/ExpenseTable';
import { Project } from 'utilities/api/models';
import { connect } from 'react-redux';
import { displayMessage } from 'actions/ActionCreators';
import { get, uniq, uniqBy } from 'lodash';

/** 申請可能経費選択 */
const RequestableExpensesSelector = (props): JSX.Element => {
  const singleViewerWrapperRef = useRef<HTMLDivElement>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');

  // 申請モーダルの表示座標取得→右ドロワーの表示位置補正の為に使用
  const offsetY = singleViewerWrapperRef.current?.getBoundingClientRect().y;
  const offsetX = singleViewerWrapperRef.current?.getBoundingClientRect().x;

  /** 経費一覧を再取得 */
  const resetExpenses = (): Promise<void> => {
    // 一括編集の場合、リセット時にモーダルを閉じる
    if (props.modalTransaction?.ids) props.closeTransactionModal();

    const { currentPage, sizePerPage } = props;
    const pageOffset = (currentPage - 1) * sizePerPage;
    return props.resetExpenses(Math.max(0, pageOffset - 1), sizePerPage + 2);
  };

  /** 削除または除外成功時にのハンドラ */
  const onDestroyOrDetachSuccess = (): void => {
    resetExpenses().then(() => {
      if (props.nextExpenseId) {
        handleGoToNextExpense();
      } else {
        props.closeTransactionModal();
      }
    });
  };

  /** 経費更新成功時のハンドラ */
  const handleUpdateSuccess = (): void => {
    props.onUpdateSuccess(null, resetExpenses);
  };

  /** 経費削除成功時のハンドラ */
  const handleDestroySuccess = (): void => {
    props.onDestroySuccess(null, onDestroyOrDetachSuccess);
  };

  /** 経費除外成功時のハンドラ */
  const handleDetachSuccess = (): void => {
    props.onDetachSuccess(null, onDestroyOrDetachSuccess);
  };

  /** 画像削除時のハンドラ */
  const handleImageDestroy = (): void => {
    props.onUpdateSuccess(i18next.t('transactions.messages.deleteImage'), resetExpenses);
  };

  /** 次の経費に進む実行時のハンドラ */
  const handleGoToNextExpense = (): void => {
    props.moveExpenseCursor(props.transactions, props.nextExpenseId, props.currentPage, props.sizePerPage);
  };

  /** 前の経費に戻る実行時のハンドラ */
  const handleGoToPreviousExpense = (): void => {
    props.moveExpenseCursor(props.transactions, props.previousExpenseId, props.currentPage, props.sizePerPage);
  };

  /** 経費分割成功時のハンドラ */
  const handleSplitSuccess = (message): void => {
    props.closeTransactionModal();
    props.onSplitSuccess(message, resetExpenses);
  };

  const { fetchEntryForms } = props;
  useEffect(() => {
    fetchEntryForms();
  }, [fetchEntryForms]);

  // 複数編集か単一編集か判別
  const selected = props.transactions.filter((x) => x && x.isSelected);
  let entryForm;
  if (props.modalTransaction) {
    if (get(props.modalTransaction, 'ids', []).length) {
      const editable = selected.every((t) => t.editable);
      entryForm = {
        id: 'multiple',
        name: i18next.t('transactions.titles.edit'),
        sort: 0,
        enable: true,
        formFields: formFieldsForMultipleEdit(selected.some((t) => t.preReportId || t.reportId))
          .map((field) => ({ ...field, editable })),
      };
    } else {
      entryForm = props.entryForms.find((form) => {
        if (form.id === 'allowance') {
          return form.directProductTableId === props.modalTransaction.directProductTableId;
        }

        return form.id === props.modalTransaction.formId;
      });
    }
  }

  /** [プロジェクトから選択]ドロップダウン */
  const renderProjectSelector = (): JSX.Element => {
    const projects: Project[] = props.expenses.filter((e) => e && e.project).map((e) => e.project);
    const uniqProjects = uniqBy(projects, 'id');

    // 一度選択したプロジェクトを、他ページで再度選択しやすいように先頭へ移動
    const selectedProjectIndex = uniqProjects.findIndex((project) => project.id === selectedProjectId);
    if (selectedProjectIndex !== -1) {
      uniqProjects.splice(selectedProjectIndex, 1);
      uniqProjects.unshift(projects.find((project) => project.id === selectedProjectId) as Project);
    }

    const handleSelectProject = (projectId: string): void => {
      setSelectedProjectId(projectId);
      props.onSelectByProject(projectId || '');
    };

    return (
      <Dropdown
        id='select-expenses-by-project'
        direction='down'
        align='right'
        menuMaxHeight='calc(100vh - 200px)'
        toggleButton={ (
          <DropdownToggleButton
            bsRole='toggle'
            styleType='link-primary'
          >
            { i18next.t('reports.requests.selectByProject') }
          </DropdownToggleButton>
        ) }
      >
        {uniqProjects.length === 0 && (
          <MenuItem disabled>
            { i18next.t('reports.messages.noSelectableProjects') }
          </MenuItem>
        )}

        {uniqProjects.map((project) => (
          <MenuItem
            key={ project.id }
            onClick={ (): void => handleSelectProject(project.id) }>
            { formatter.project(project, null, null, ['displayId', 'name']) }
          </MenuItem>
        ))}
      </Dropdown>
    );
  };

  /** [請求先から選択]ドロップダウン */
  const renderPayerSelector = (): JSX.Element => {
    return (
      <Dropdown
        id='select-expenses-by-project'
        direction='down'
        align='right'
        menuMaxHeight='calc(100vh - 200px)'
        toggleButton={ (
          <DropdownToggleButton
            bsRole='toggle'
            styleType='link-primary'
          >
            { i18next.t('reports.requests.selectPayer') }
          </DropdownToggleButton>
        ) }
      >
        <MenuItem
          onClick={ (): void => props.onSelectByPayer('advancedPayment') }
        >
          {  i18next.t('reports.requests.selectAdvancedPayment') }
        </MenuItem>
        <MenuItem
          onClick={ (): void => props.onSelectByPayer('corporatePayment') }
        >
          {  i18next.t('reports.requests.selectCorporatePayment') }
        </MenuItem>
      </Dropdown>
    );
  };

  /** 選択項目の操作が行われた後に呼ばれるcallback（データの再取得） */
  const handleResetExpenses = (): void => {
    // 経費順送り機能でページ端の経費から前後の経費に移動するために、ページ外の経費も取得しておく必要がある
    const pageOffset = (props.currentPage - 1) * props.sizePerPage;
    return props.resetExpenses(Math.max(0, pageOffset - 1), props.sizePerPage + 2);
  };

  /** 経費選択時の操作メニュー */
  const renderSelectedItemsControlMenu = (): JSX.Element => {
    const selectedTransactions = props.expenses.filter((x) => x && x.isSelected);

    /** 一括編集モーダルを開く */
    const handleOpenEditAllModal = (): void => {
      let preReportTitle = null;

      if (uniq(selectedTransactions.map((x) => x.preReportTitle)).length === 1) {
        preReportTitle = get(selectedTransactions, '[0].preReportTitle', null);
      }

      props.onClickUpdateAllButton(
        { ...props.defaultTransaction, ids: selectedTransactions.map((x) => x.id) },
        preReportTitle,
      );
    };

    return (
      <>
        <MenuItem
          key='editAll'
          disabled={ selectedTransactions.length < 2 }
          onClick={ handleOpenEditAllModal }
        >
          { i18next.t('transactions.index.editSelectedMulti') }
        </MenuItem>
        <MenuItem
          key='mergeSelected'
          disabled={ !props.manualMergeable || selectedTransactions.length < 2 }
          onClick={ props.openMergeableTransactionsPairsSearchModal }
        >
          { i18next.t('transactions.index.mergeSelected') }
        </MenuItem>
        <MenuItem
          key='deleteSelected'
          color='red'
          disabled={ selectedTransactions.length < 1 }
          onClick={ (): void => setShowDeleteConfirmModal(true) }
        >
          { i18next.t('commons.actions.delete') }
        </MenuItem>
      </>
    );
  };

  /** 選択した経費を編集するボタン */
  const renderEditSelectedButton = (): JSX.Element | null => {
    const handleOpenEditAllModal = (): void => {
      if (props.selectedIds.length >= 2) {
        props.onClickUpdateAllButton(
          { ...props.defaultTransaction, ids: props.selectedIds },
        );
      } else {
        const selectedExpense = props.expenses.find((expense) => expense.id === props.selectedIds[0]);
        props.openTransactionModal(props.expenses, selectedExpense, props.currentPage, props.sizePerPage);
      }
    };

    return (
      <Button
        onClick={ handleOpenEditAllModal }
        type='button'
        styleType='secondary'
        disabled={ !props.selectedIds.length }
      >
        { i18next.t('transactions.index.editSelected') }
      </Button>
    );
  };

  return (
    <div>
      <div style={ { display: 'flex', alignItems: 'center' } }>
        <Button styleType='link-primary' onClick={ props.onClickSelectThisMonths }>
          { i18next.t('reports.requests.selectThisMonth') }
        </Button>
        <Button styleType='link-primary' onClick={ props.onClickSelectLastMonths }>
          { i18next.t('reports.requests.selectLastMonth') }
        </Button>
        { props.projectSelectable && renderProjectSelector() }
        { props.payerSelectable && renderPayerSelector() }
      </div>
      <div style={ { margin: '8px 0' } }>
        {renderEditSelectedButton()}
      </div>
      <ExpenseTable
        columns={ props.expenseTableColumns }
        expenses={ props.expenses }
        currentPage={ props.currentPage }
        sizePerPage={ props.sizePerPage }
        fetchExpenses={ props.fetchExpenses }
        onSortChange={ props.onSortChange }
        onPageChange={ props.onPageChange }
        onSizePerPageList={ props.onSizePerPageList }
        onSelect={ props.onSelect }
        onSelectAll={ props.onSelectAll }
        expensesObj={ props.transactionsObj }
        onColumnWidthChange={ props.onColumnWidthChange }
        openExpenseModal={ props.openTransactionModal }
        isExpenseModalOpen={ props.isTransactionModalOpen }
        modalExpense={ props.modalTransaction }
        pairsSearch={ props.pairsSearch }
        closeMergeableTransactionsPairsSearchModal={ props.closeMergeableTransactionsPairsSearchModal }
        editOption={ props.editOption }
        mergeSelecter={ props.mergeSelecter }
        searchMergeableTransactionsPairs={ props.searchMergeableTransactionsPairs }
        mergeTransactions={ props.mergeTransactions }
        closeMergedSelecter={ props.closeMergedSelecter }
        selectMergedTransaction={ props.selectMergedTransaction }
        isLoading={ props.inProcess }
        maxHeight='calc(100vh - 360px)'
        renderSelectedItemsControlMenu={ renderSelectedItemsControlMenu }
        showItemsCount={ false }
      />

      {/* モーダル内に右ドロワーが入る際に、モーダルの領域を無視して画面全体に右ドロワーが広がるよう、画面全体を覆うdivを設置 */}
      <div style={ { position: 'fixed', top: 0, left: 0 } } ref={ singleViewerWrapperRef } />
      {offsetX !== undefined && offsetY !== undefined && (
        <div style={ {
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          left: `-${offsetX}px`,
          top: `-${offsetY}px`,
          transform: `translate(0, 0)`,
          zIndex: 9999,
          pointerEvents: 'none', // 後ろのテーブル要素を触れるようにする
          overflowY: 'hidden',
        } }>
          <ExpenseSingleViewerWrapper
            show={ props.isTransactionModalOpen }
            transaction={ { ...props.modalTransaction, defaultPeriod: props.defaultPeriod } }
            formFields={ get(entryForm, 'formFields', []) }
            authority={ props.authority }
            onUpdateSuccess={ handleUpdateSuccess }
            onDestroySuccess={ handleDestroySuccess }
            onDetachSuccess={ handleDetachSuccess }
            onDeleteImage={ handleImageDestroy }
            onSplitSuccess={ handleSplitSuccess }
            closeModal={ props.closeTransactionModal }
            shouldSelectSelfAsCompanion={ props.shouldSelectSelfAsCompanion }
            onSelectCompanionsCategory={ props.onSelectCompanionsCategory }
            onRotateImage={ props.rotateReceiptFile }
            ownerId={ props.modalTransaction?.ownerId || props.ownerId }
            onGoToPreviousExpense={ props.previousExpenseId ? handleGoToPreviousExpense : void 0 }
            onGoToNextExpense={ props.nextExpenseId ? handleGoToNextExpense : void 0 }
          />
        </div>
      )}
      <DeleteConfirmModal
        show={ showDeleteConfirmModal }
        onHide={ (): void => setShowDeleteConfirmModal(false) }
        onConfirm={ (): void => {
          props.destroyAll(handleResetExpenses);
          setShowDeleteConfirmModal(false);
        } }
      />

      <ReportSendModal
        show={ props.isReportSendModalOpen }
        close={ props.closeReportSendModal }
        transactionIds={ props.selectedIds }
        isPersonal={ !props.isCorporate }
      />
    </div>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
function mapDispatchToProps(dispatch) {
  return {
    fetchEntryForms() {
      return dispatch(actions.fetchEntryForms(true));
    },
    onClickUpdateAllButton(transaction) {
      dispatch(actions.setUpdateAllModalTransaction(transaction, transaction.preReportTitle));
      dispatch(actions.openTransactionModal());
    },
    onCreateSuccess(message, callback) {
      dispatch(displayMessage('success', message || i18next.t('transactions.messages.create')));
      callback();
    },
    onUpdateSuccess(message, callback) {
      dispatch(displayMessage('success', message || i18next.t('transactions.messages.update')));
      callback();
    },
    onDestroySuccess(message, callback) {
      dispatch(displayMessage('success', message || i18next.t('transactions.messages.delete')));
      callback();
    },
    onDetachSuccess(message, callback) {
      dispatch(displayMessage('success', message || i18next.t('transactions.messages.detach')));
      callback();
    },
    onSplitSuccess(message, callback) {
      dispatch(displayMessage('success', message));
      callback();
    },
    onSelectCompanionsCategory() {
      dispatch(actions.setShouldSelectSelfAsCompanion(false));
    },
    destroyAll(callback) {
      dispatch(actions.destroyTransactions(callback));
    },
    openTransactionModal(expenses, expense, currentPage, sizePerPage) {
      dispatch(actions.openTransactionEditModal(expenses, expense, currentPage, sizePerPage, { requireMergeableAggregation: true, isRequestableExpenses: true }));
    },
    closeTransactionModal() {
      dispatch(actions.setShouldSelectSelfAsCompanion(true));
      dispatch(actions.closeTransactionModal());
    },
    moveExpenseCursor(expenses, targetExpenseId, currentPage, sizePerPage) {
      dispatch(actions.moveExpenseCursor(expenses, targetExpenseId, currentPage, sizePerPage, { requireMergeableAggregation: true }));
    },
    rotateReceiptFile(expenseId, fileId, rotation) {
      dispatch(actions.rotateReceiptFile(expenseId, fileId, rotation));
    },
    openMergeableTransactionsPairsSearchModal() {
      dispatch(manualMergeActions.openMergeableTransactionsPairsSearchModal());
    },
    closeMergeableTransactionsPairsSearchModal() {
      dispatch(manualMergeActions.closeMergeableTransactionsPairsSearchModal());
    },
    editOption(key, value) {
      dispatch(manualMergeActions.editOption(key, value));
    },
    searchMergeableTransactionsPairs() {
      dispatch(manualMergeActions.searchMergeableTransactionsPairs());
    },
    closeMergedSelecter() {
      dispatch(manualMergeActions.closeMergedSelecter());
    },
    mergeTransactions() {
      dispatch(manualMergeActions.mergeTransactions());
    },
    selectMergedTransaction(transacitons) {
      dispatch(manualMergeActions.selectMergedTransaction(transacitons));
    },
    onPageChange(page) {
      dispatch(actions.setCurrentPage(page));
    },
    onSizePerPageList(sizePerPage) {
      dispatch(actions.setSizePerPage(sizePerPage));
    },
    onSelect(transactionsObj, transaction, isSelected) {
      dispatch(actions.selectTransaction(transactionsObj, transaction, isSelected));
    },
    onSelectAll(currentPage, sizePerPage, transactionsObj, isSelected) {
      dispatch(actions.selectAllTransactions(currentPage, sizePerPage, transactionsObj, isSelected));
    },
    onColumnWidthChange(id, width) {
      dispatch(actions.changeColumnWidth(id, width));
    },
  };
}

export default connect(
  makeTransactionTableStateMapper,
  mapDispatchToProps,
)(RequestableExpensesSelector);
