import { Category } from "../types";

/**
 * 選択中の経費科目から、階層を考慮した経費科目リストを初期化する。
 * @param selectedCategory 選択中の経費科目
 * @param categories 経費科目マスタ
 * @returns 階層を考慮した経費科目リスト
 */
export const buildNestedCategories = (
  selectedCategory: Category | null,
  categories: Category[],
): Category[] => {
  if (selectedCategory) {
    if (categories.length > 0) {
      const categoryInMaster = categories.find(
        (c) => c.id === selectedCategory.id,
      );

      if (categoryInMaster) {
        // 選択中の経費科目がマスタに存在する。
        return getParentCategoriesAndSelf(categoryInMaster, categories);
      }
      // 選択中の経費科目がマスタに存在しない。
      return [selectedCategory];
    }
    // 経費科目マスタが取得されていない。
    return [selectedCategory];
  }
  // 経費科目が設定されていない。
  return [];
};

export const getParentCategoriesAndSelf = (
  category: Category,
  categories: Category[],
  nestedCategories: Category[] = [],
): Category[] => {
  const nextNestedCategories = nestedCategories.concat(category);
  const parentCategory = categories.find((c) => c.id === category.parentId);

  if (typeof parentCategory === "undefined") {
    // 親から子供順で返す
    return nextNestedCategories.reverse();
  }

  return getParentCategoriesAndSelf(
    parentCategory,
    categories,
    nextNestedCategories,
  );
};
